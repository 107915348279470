import Layout from "./components/Layout";
import CreateRoutes from "./routes";

function App() {
  return (
    <Layout>
      <a href="https://unictecnologia.com.br">
        <img className="logo" src="logo.png" alt="logo unic" />
      </a>
      <CreateRoutes />
    </Layout>
  );
}

export default App;
