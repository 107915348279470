import styled from "styled-components";

export const FilterButton = styled.a`
  background: #b28e6e;
  color: #FFF;
  font-weight: 600;
  text-decoration: none;
  width: 170px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const Button = styled.a`
  background: #b28e6e;
  color: #FFF;
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-transform: normal;
  cursor: pointer;
  flex-direction: column;
  padding: 8px 0;

  span.subtitle {
    font-size: 12px;
    text-transform: normal;
  }

  &:hover {
    filter: brightness(0.95);
  }
`;