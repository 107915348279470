import React from "react";
import { Button } from "../../../../components/Button";

interface CameraNotAllowedProps {
  setCameraPermission: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const CameraNotAllowed: React.FC<CameraNotAllowedProps> = ({
  setCameraPermission,
}) => {
  const handleRetryPermission = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setCameraPermission(true);
        stream.getTracks().forEach((track) => track.stop());
      } else {
        throw new Error("getUserMedia is not supported in this browser");
      }
    } catch (error) {
      alert(error);
      setCameraPermission(false);
    }
  };

  return (
    <div>
      <p>Para usar nossa ferramenta, você precisa habilitar sua câmera.</p>
      <Button onClick={handleRetryPermission}>Habilitar câmera</Button>
    </div>
  );
};

export default CameraNotAllowed;
