import React, { useEffect, useRef, useState } from "react";
import { CameraWrapper, OverlayImage } from "../styles";
import Webcam from "react-webcam";

interface WebcamComponentProps {
  filterPath: string;
}

const WebcamComponent: React.FC<WebcamComponentProps> = ({ filterPath }) => {
  const [webcamReady, setWebcamReady] = useState(false);

  useEffect(() => {
    webcamRef.current?.video?.addEventListener("loadeddata", () => {
      setWebcamReady(true);
    });
  }, []);

  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);

  const webcamStyle: React.CSSProperties = {
    height: 376,
    width: 275,
  };

  const videoConstraints = {
    facingMode: "user",
  };

  const isDesktop = window.innerWidth > 768;

  return (
    <CameraWrapper isDesktop={isDesktop}>
      <Webcam
        audio={false}
        ref={webcamRef}
        style={webcamStyle}
        mirrored={true}
        autoPlay={true}
        videoConstraints={videoConstraints}
      />
      <OverlayImage src={filterPath} alt="Overlay" />
    </CameraWrapper>
  );
};

export default WebcamComponent;
