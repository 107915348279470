import { FaceFormatListItemProps } from "../interfaces/faceFormatList";

export const FaceFormatList: FaceFormatListItemProps[] = [
  {
    name: 'Oval',
    imgPath: 'faces/1-face-oval.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_oval.pdf',
    thumbImg: 'thumbs/1-oval-thumb.png',
  },
  {
    name: 'Redondo',
    imgPath: 'faces/2-face-redondo.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_redondo.pdf',
    thumbImg: 'thumbs/2-redondo-thumb.png',
  },
  {
    name: 'Quadrado',
    imgPath: 'faces/3-face-quadrado.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_quadrado.pdf',
    thumbImg: 'thumbs/3-quadrado-thumb.png',
  },
  {
    name: 'Retangular',
    imgPath: 'faces/4-face-retangular.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_retangular.pdf',
    thumbImg: 'thumbs/4-retangular-thumb.png',
  },
  {
    name: 'Hexagonal Base Reta',
    imgPath: 'faces/5-face-hbasereta.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_hexagonal_base_reta.pdf',
    thumbImg: 'thumbs/5-hbasereta-thumb.png',
  },
  {
    name: 'Hexagonal Lateral Reta',
    imgPath: 'faces/6-face-hlateralreta.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_hexagonal_lateral_reta.pdf',
    thumbImg: 'thumbs/6-hlateralreta-thumb.png',
  },
  {
    name: 'Triângulo Invertido',
    imgPath: 'faces/7-face-triangulo-invertido.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_triangulo_invertido.pdf',
    thumbImg: 'thumbs/7-trianguloinvertido-thumb.png',
  },
  {
    name: 'Triangular',
    imgPath: 'faces/8-face-triangular.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_triangular.pdf',
    thumbImg: 'thumbs/8-triangular-thumb.png',
  },
  {
    name: 'Losango',
    imgPath: 'faces/9-face-losango.png',
    categoryLink: 'https://demo.unicjewels.com.br/files/dossie_losango.pdf',
    thumbImg: 'thumbs/9-losango-thumb.png',
  },
];