import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import CameraPage from "../pages/Camera";
import HomePage from "../pages/Home";

const CreateRoutes: React.FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route index path="/tool" element={<CameraPage />} />
      </Routes>
    </HashRouter>
  );
};

export default CreateRoutes;
