import styled from "styled-components";

interface CameraWrapperProps {
  isDesktop: boolean;
}

export const CameraWrapper = styled.div<CameraWrapperProps>`
  position: relative;
  width: 275px;
  height: 376px;

  video {
    object-fit: ${({ isDesktop }) => isDesktop ? 'cover' : 'initial'};
  }
`;

export const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CameraContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
`;

export const SliderArea = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1e9;
  padding: 10px 25px 25px 25px;
  align-items: center;
  gap: 8px;
`;

export const SliderAreaTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0;
  text-transform: uppercase;
`;

export const SliderThumbs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
`;