import React from "react";
import {
  HomeContainer,
  HomeWrapper,
  IconContainer,
  List,
  ListItemContainer,
} from "./styles";
import CameraIcon from "./components/icons/Camera";
import { Button } from "../../components/Button";
import SolIcon from "./components/icons/Sol";
import HairIcon from "./components/icons/HairIcon";
import UserIcon from "./components/icons/UserIcon";

interface ListItem {
  id: number;
  icon: React.ReactNode;
  text: string;
}

const HomePage: React.FC = () => {
  const items: ListItem[] = [
    {
      id: 1,
      icon: <CameraIcon />,
      text: "Será necessário ligar a câmera (celular ou computador);",
    },
    {
      id: 2,
      icon: <SolIcon />,
      text: "Estar num lugar bem iluminado para facilitar na identificação;",
    },
    { id: 3, icon: <HairIcon />, text: "Cabelo preso;" },
    {
      id: 4,
      icon: <UserIcon />,
      text: "Posicionar-se de frente para a câmera, olhando diretamente para ela.",
    },
  ];

  return (
    <HomeContainer>
      <HomeWrapper>
        <h1>
          para melhor identificação do <span>formato do rosto:</span>
        </h1>

        <List>
          {items.map((item) => (
            <ListItemContainer key={item.id.toString()}>
              <IconContainer>{item.icon}</IconContainer>
              <span>{item.text}</span>
            </ListItemContainer>
          ))}
        </List>

        <div style={{ width: "200px" }}>
          <Button href="/#/tool">Entendi</Button>
        </div>
      </HomeWrapper>
    </HomeContainer>
  );
};

export default HomePage;
