import styled from "styled-components";

export const HomeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HomeWrapper = styled.div`
  width: 920px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: url('/home-bg.png') no-repeat left center;
  background-size: contain;

  @media (max-width: 768px) {
    background: none;
  }

  h1 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;

    span {
      color: #B28E6C;
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  margin-top: 16px;
  padding: 0;
`;

export const ListItemContainer = styled.li`
  display: flex;
  align-items: center;
  padding: 4px;
  line-height: 1;
  font-weight: 500;

  span {
    text-align: left;
  }
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  width: 35px;
  height: 35px;

  svg {
    width: 35px;
    height: 35px;
  }
`;