import styled from "styled-components";

interface ThumbButtonProps {
  path: string;
  selected: boolean;
}

export const ThumbButton = styled.div<ThumbButtonProps>`
  background: url(${({ path }) => path}) no-repeat center center;
  background-size: 34px 43px;
  width: 34px;
  height: 43px;
  border: ${({ selected }) => selected ? '2px solid #b28e6e' : 'none'};
  cursor: pointer;
  border-radius: 2px;
`;