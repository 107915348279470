import React, { useEffect, useState } from "react";

import {
  CameraContainer,
  SliderArea,
  SliderAreaTitle,
  SliderThumbs,
} from "./styles";
import WebcamComponent from "./components/Webcam";
import { Button } from "../../components/Button";
import { FaceFormatList } from "../../utils/faceFormatList";
import { FaceFormatListItemProps } from "../../interfaces/faceFormatList";
import { ThumbButton } from "../../components/ThumbButton";
import CameraNotAllowed from "./components/CameraNotAllowed";

const CameraPage: React.FC = () => {
  const [selectedFace, setSelectedFace] = useState<FaceFormatListItemProps>(
    {} as FaceFormatListItemProps
  );
  const [cameraPermission, setCameraPermission] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setCameraPermission(true);
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        setCameraPermission(false);
      }
    };

    checkCameraPermission();
  }, []);

  useEffect(() => {
    const defaultFaceName = "Oval";
    const defaultFaceSelected = FaceFormatList.find(
      (face) => face.name === defaultFaceName
    );

    if (!defaultFaceSelected) return;

    setSelectedFace(defaultFaceSelected);
  }, []);

  const handleChangeFace = (selectedFace: FaceFormatListItemProps) => {
    setSelectedFace(selectedFace);
  };

  return (
    <CameraContainer>
      <SliderArea>
        {cameraPermission ? (
          <>
            <SliderAreaTitle>{selectedFace.name}</SliderAreaTitle>
            <WebcamComponent filterPath={selectedFace.imgPath} />
            <SliderThumbs>
              {FaceFormatList.map((face, index) => (
                <ThumbButton
                  title={face.name}
                  key={index.toString()}
                  path={face.thumbImg}
                  onClick={() => handleChangeFace(face)}
                  selected={Boolean(face.name === selectedFace.name)}
                />
              ))}
            </SliderThumbs>
            <Button href={selectedFace.categoryLink}>
              Acesse seu dossiê
              <span className="subtitle">Rosto {selectedFace.name}</span>
            </Button>
          </>
        ) : (
          <CameraNotAllowed setCameraPermission={setCameraPermission} />
        )}
      </SliderArea>
    </CameraContainer>
  );
};

export default CameraPage;
