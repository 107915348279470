import React from "react";

const CameraIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="51"
      zoomAndPan="magnify"
      viewBox="0 0 38.25 38.249999"
      height="51"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="912db894b3">
          <path
            d="M 0.519531 0.324219 L 37.1875 0.324219 L 37.1875 36.996094 L 0.519531 36.996094 Z M 0.519531 0.324219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="466925d436">
          <path
            d="M 18.851562 0.324219 C 8.726562 0.324219 0.519531 8.535156 0.519531 18.660156 C 0.519531 28.785156 8.726562 36.996094 18.851562 36.996094 C 28.976562 36.996094 37.1875 28.785156 37.1875 18.660156 C 37.1875 8.535156 28.976562 0.324219 18.851562 0.324219 Z M 18.851562 0.324219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="5d906eb4d9">
          <path
            d="M 7.761719 9.960938 L 29.828125 9.960938 L 29.828125 27.613281 L 7.761719 27.613281 Z M 7.761719 9.960938 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#912db894b3)">
        <g clip-path="url(#466925d436)">
          <path
            fill="#b28e6c"
            d="M 0.519531 0.324219 L 37.1875 0.324219 L 37.1875 36.996094 L 0.519531 36.996094 Z M 0.519531 0.324219 "
            fill-opacity="1"
            fill-rule="nonzero"
          />
        </g>
      </g>
      <g clip-path="url(#5d906eb4d9)">
        <path
          fill="#ffffff"
          d="M 28.746094 12.898438 L 24.117188 12.898438 L 23.960938 11.792969 L 23.839844 11.046875 C 23.792969 10.746094 23.632812 10.496094 23.382812 10.289062 C 23.128906 10.074219 22.855469 9.964844 22.554688 9.964844 L 15.023438 9.964844 C 14.722656 9.964844 14.449219 10.070312 14.195312 10.289062 C 13.941406 10.503906 13.808594 10.757812 13.761719 11.046875 L 13.640625 11.792969 L 13.472656 12.898438 L 8.867188 12.898438 C 8.253906 12.898438 7.761719 13.390625 7.761719 13.980469 L 7.761719 26.246094 C 7.761719 26.859375 8.253906 27.351562 8.867188 27.351562 L 28.746094 27.351562 C 29.359375 27.351562 29.851562 26.859375 29.851562 26.246094 L 29.851562 13.980469 C 29.851562 13.390625 29.359375 12.898438 28.746094 12.898438 Z M 28.515625 26.015625 L 9.085938 26.015625 L 9.085938 14.21875 L 13.472656 14.21875 C 14.136719 14.21875 14.707031 13.726562 14.796875 13.089844 L 14.953125 11.984375 L 15.074219 11.296875 L 22.527344 11.296875 L 22.636719 11.984375 L 22.804688 13.089844 C 22.902344 13.726562 23.480469 14.21875 24.117188 14.21875 L 28.515625 14.21875 Z M 28.515625 26.015625 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#ffffff"
        d="M 26.375 17.359375 C 26.953125 17.359375 27.433594 16.867188 27.433594 16.289062 C 27.433594 15.699219 26.964844 15.207031 26.375 15.207031 C 25.789062 15.207031 25.320312 15.699219 25.320312 16.289062 C 25.320312 16.867188 25.800781 17.359375 26.375 17.359375 Z M 26.375 17.359375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 18.789062 14.570312 C 17.429688 14.570312 16.25 15.0625 15.277344 16.035156 C 14.304688 17.011719 13.8125 18.1875 13.8125 19.546875 C 13.8125 20.90625 14.304688 22.085938 15.277344 23.058594 C 16.25 24.03125 17.429688 24.492188 18.789062 24.515625 C 21.484375 24.554688 23.800781 22.21875 23.765625 19.546875 C 23.75 18.175781 23.273438 17.011719 22.300781 16.035156 C 21.328125 15.0625 20.160156 14.570312 18.789062 14.570312 Z M 21.363281 22.132812 C 20.652344 22.84375 19.800781 23.191406 18.789062 23.191406 C 17.792969 23.191406 16.9375 22.84375 16.226562 22.132812 C 15.519531 21.421875 15.167969 20.558594 15.167969 19.546875 C 15.167969 18.550781 15.519531 17.683594 16.226562 16.972656 C 16.9375 16.265625 17.792969 15.902344 18.789062 15.902344 C 19.800781 15.902344 20.652344 16.265625 21.363281 16.972656 C 22.070312 17.683594 22.433594 18.550781 22.433594 19.546875 C 22.433594 20.558594 22.070312 21.421875 21.363281 22.132812 Z M 21.363281 22.132812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
};

export default CameraIcon;
